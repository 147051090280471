import ToggleGalleryButton from '@components/toggle-gallery-button/toggle-gallery-button';
import {ExportedPhotoData} from '@customTypes/app';
import {
  selectHoveredImageId,
  selectSelectedImageId
} from '@store/flickr-selectors';
import {setHoveredImageId} from '@store/flickr-slice';
import cx from 'classnames';
import {FunctionComponent, forwardRef, useEffect, useRef, Ref} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import styles from './image-container.module.css';

interface ImageContainerProps {
  image: ExportedPhotoData['photos'][0];
  ref?: Ref<HTMLDivElement>;
  onClick: () => void;
}

const ImageContainer: FunctionComponent<ImageContainerProps> = forwardRef(
  function ImageContainer(props, ref) {
    const {image, onClick} = props;

    const selectedImageId = useSelector(selectSelectedImageId);
    const hoveredImageId = useSelector(selectHoveredImageId);
    const hoveredMarkerId = useSelector(selectHoveredImageId);

    const dispatch = useDispatch();

    const fieldRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
      if (selectedImageId === image.id && fieldRef.current) {
        fieldRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        });
      }
    }, [selectedImageId, image.id]);

    return (
      <>
        <div
          className={styles['container-image']}
          key={image.id}
          ref={ref ? ref : null}>
          <img
            className={cx(
              selectedImageId === image.id && styles.selected,
              hoveredImageId === image.id && styles.hovered,
              hoveredMarkerId === image.id && styles['marker-hovered']
            )}
            key={image.id}
            src={image.url}
            alt={image.title}
            ref={fieldRef}
            loading="lazy"
            onMouseEnter={() => {
              dispatch(setHoveredImageId(image.id));
            }}
            onMouseOut={() => {
              dispatch(setHoveredImageId(null));
            }}
            onClick={onClick}
          />
        </div>
        {image.id && (
          <div
            className={cx(
              styles['sidebar-info-container'],
              selectedImageId === image.id && styles.selected
            )}>
            <div className={styles['button-container']}>
              <ToggleGalleryButton
                className={styles['gallery-toggle-button']}
              />
              {image.flickrUrl && (
                <a href={image.flickrUrl} target="_blank" rel="noreferrer">
                  Open in flickr
                </a>
              )}
            </div>
            <table className={styles['info-table']}>
              <tbody>
                <tr>
                  <td>Title:</td>
                  <td>{image?.title.toString()}</td>
                </tr>
                <tr>
                  <td>Date:</td>
                  <td>{new Date(image?.datetaken).toLocaleString()}</td>
                </tr>
                <tr>
                  <td>Owner:</td>
                  <td>{image?.ownername.toString()}</td>
                </tr>
                <tr>
                  <td>Description:</td>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: image?.description.toString()
                    }}></td>
                </tr>
                <tr>
                  <td>Tags:</td>
                  <td>{image?.tags.toString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </>
    );
  }
);

export default ImageContainer;
