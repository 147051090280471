import {FunctionComponent} from 'react';
import SVGIcon, {SVGIconProps} from '@components/svg-icon/svg-icon';

interface expandAndFoldIconInterface extends SVGIconProps {
  showExpanded: boolean;
  className?: string;
}

const ExpandAndFoldIcon: FunctionComponent<
  expandAndFoldIconInterface
> = props => {
  const {showExpanded, ...rest} = props;

  const path = showExpanded
    ? 'M200 856V616h60v180h180v60H200Zm500-320V356H520v-60h240v240h-60Z'
    : 'm343 896-43-43 180-180 180 180-43 43-137-137-137 137Zm137-417L300 299l43-43 137 137 137-137 43 43-180 180Z';

  return (
    <SVGIcon {...rest} height="20" viewBox="0 96 960 960" width="20">
      <path d={path}></path>
    </SVGIcon>
  );
};

export default ExpandAndFoldIcon;
