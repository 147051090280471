import {FunctionComponent} from 'react';
import SVGIcon, {SVGIconProps} from '../svg-icon/svg-icon';

const SearchIcon: FunctionComponent<SVGIconProps> = props => (
  <SVGIcon {...props} strokeWidth="2" stroke="currentColor" fill="none">
    <path
      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      strokeLinejoin="round"
      strokeLinecap="round"></path>
  </SVGIcon>
);

export default SearchIcon;
