import {FunctionComponent} from 'react';
import SVGIcon, {SVGIconProps} from '@components/svg-icon/svg-icon';

const ArrowIconBack: FunctionComponent<SVGIconProps> = props => (
  <SVGIcon
    {...props}
    strokeWidth="2"
    stroke="currentColor"
    fill="currentColor"
    viewBox="0 0 48 48">
    <path
      d="M24 40 8 24 24 8l2.1 2.1-12.4 12.4H40v3H13.7l12.4 12.4Z"
      strokeLinejoin="round"
      strokeLinecap="round"></path>
  </SVGIcon>
);

export default ArrowIconBack;
