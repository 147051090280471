import {ParamNames} from '@customTypes/app';
import {UserInputState} from '@customTypes/user-input';

const serializeQuery = (input: UserInputState, imageId?: string | null) => {
  const params = new URLSearchParams();

  if (input.value && input.value.length > 0) {
    params.set(input.type, [...input.value].join('&'));
  }

  if (imageId) {
    params.set(ParamNames.ID, imageId);
  }

  window.location.hash = `/${params.toString().toLocaleLowerCase()}`;
};

export default serializeQuery;
