import ReactDOM from 'react-dom/client';
import App from './app';
import {Provider} from 'react-redux';
import store from '@store/redux-store';
import {StrictMode} from 'react';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);
