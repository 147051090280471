/* eslint-disable camelcase */
import SetUserInput, {UserInputState} from '@customTypes/user-input';
import {
  FlickrUserGetInfoResponse,
  ImagePhotoData,
  InputType,
  SearchParameters,
  UserSettings
} from '@customTypes/app';
import {PhotosSlice} from './photos-slice';
import {getParamsFromUrl} from '@utils/get-params-from-url';
import {fetchUserDataByUsername} from '@utils/get-user-info';

export interface FlickrContextInterface {
  isDrawerOpen: boolean;
  isGalleryVisible: boolean;
  input: UserInputState;
  isExploreModeEnabled: boolean;
  addedInput: string | null;
  tags: Array<string>;
  user: null | FlickrUserGetInfoResponse['person'];
  hoveredImageId: string | null;
  hoveredMarkerId: string | null;
  selectedImageId: string | null;
  searchParameters: Partial<SearchParameters>;
  userSettings: UserSettings;
}

const {type, value, selectedImageId} = getParamsFromUrl();

// This should be simpler using top level await
let user: FlickrUserGetInfoResponse['person'] | null = null;

if (type === InputType.USERNAME) {
  (async () => {
    user = await fetchUserDataByUsername(value.toLocaleString());
  })();
}

const tags = type === InputType.TAG ? value : [];

export const initialState: FlickrContextInterface = {
  input: {
    type,
    value
  },
  isDrawerOpen: false,
  isGalleryVisible: false,
  addedInput: null,
  user,
  tags,
  hoveredImageId: null,
  hoveredMarkerId: null,
  isExploreModeEnabled: false,

  selectedImageId,
  userSettings: {
    explore_mode: false
  },

  searchParameters: {
    src: 'loc.alize.us',
    sort: 'interestingness-desc',
    min_upload_date: 1,
    extras: [
      'description',
      'tags',
      'owner_name',
      'icon_server',
      'geo',
      'date_taken',
      'tags',
      'user_id'
    ].toLocaleString(),
    tag_mode: 'all',
    per_page: 50,
    has_geo: true,
    safe_search: 3
  }
};
export interface StoreFlickActions {
  setPhotos: (
    photos: Array<ImagePhotoData>,
    isInfiniteScroll?: boolean
  ) => void;
  setSearchParams: (params: SearchParameters) => void;
  setUserInput: (userInput: SetUserInput) => void;
  setUserSettings: (value: UserSettings) => void;
  setTags: (value: string[]) => void;
  setHoveredMarkerId: (value: string | null) => void;
  setHoveredImageId: (value: string | null) => void;
}
export interface State {
  flickr: FlickrContextInterface;
  flickrPhotos: PhotosSlice;
}
