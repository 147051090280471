import {Middleware, configureStore} from '@reduxjs/toolkit';
import flickrReducer from './flickr-slice.tsx';
import photosSlice from './photos-slice.tsx';
import {createLogger} from 'redux-logger';

const isProduction = import.meta.env.PROD;

const store = configureStore({
  reducer: {
    flickr: flickrReducer,
    flickrPhotos: photosSlice
  },
  middleware: getDefaultMiddleware =>
    isProduction
      ? getDefaultMiddleware()
      : getDefaultMiddleware().concat(
          createLogger({
            collapsed: true
          }) as Middleware
        )
});

export default store;
