if (!import.meta.env.VITE_GOOGLE_MAPS_API_KEY) {
  throw new Error('No GOOGLE_MAPS_API_KEY provided');
}

export const GOOGLE_MAPS_API_KEY: string = import.meta.env
  .VITE_GOOGLE_MAPS_API_KEY;

export const mapOptions = {
  center: {lat: 53.5582447, lng: 9.647645},
  zoom: 2.5,
  mapId: '5a6c9b4167f21994',
  disableDefaultUI: false
};
