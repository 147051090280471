import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useFetchPopularTags} from '@hooks/use-fetch-popular-tags';
import {useGetInputType} from '@hooks/use-get-input-type';
import {selectTags, selectUserInput} from '@store/flickr-selectors';
import {deselectInputTag, setInputTag, setTags} from '@store/flickr-slice';

import styles from '@components/tags/tags.module.css';

const Tags = () => {
  const dispatch = useDispatch();

  const tags = useSelector(selectTags);
  const input = useSelector(selectUserInput);

  const {data: popularTags, isSuccess: isSuccessTags} = useFetchPopularTags();

  useEffect(() => {
    if (!isSuccessTags) {
      return;
    }
    dispatch(setTags(popularTags));
  }, [popularTags, dispatch, isSuccessTags]);

  const onTagClickHandler = (tag: string, isTagSelected: boolean) => {
    const actionCreator = isTagSelected ? deselectInputTag : setInputTag;
    dispatch(actionCreator(tag));
  };

  const {isInputTypeTag} = useGetInputType();

  const selectedTags = isInputTypeTag ? input.value : [];

  return (
    <div className={styles['popular-tags']}>
      <ul>
        {tags.map((tag: any, index: number) => {
          const isTagSelected = selectedTags?.includes(tag) ?? false;
          return (
            <button
              role="button"
              className={`${styles.tag} ${
                isTagSelected ? styles.selected : ''
              }`}
              key={index}
              onClick={() => onTagClickHandler(tag, isTagSelected)}>
              {tag}
            </button>
          );
        })}
      </ul>
    </div>
  );
};

export default Tags;
