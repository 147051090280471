import Sidebar from '@components/sidebar/sidebar';
import TabNavigation from '@components/tab-navigation/tab-navigation';
import {ToastVariant} from '@customTypes/app';
import useDarkMode from '@hooks/use-dark-mode';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query';
import {showToast} from '@utils/show-toast';
import {FunctionComponent, PropsWithChildren} from 'react';
import {ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import GoogleMaps from '@components/map/map';

import ImageGalleryWrapper from '@components/image-gallery-wrapper/image-gallery-wrapper';
import '../styles/variables.css';

import styles from '../styles/main.module.css';

const MainContainer = ({children}: PropsWithChildren) => (
  <div className={styles['page-container']}>{children}</div>
);

const App: FunctionComponent = () => {
  const [isDarkMode] = useDarkMode();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
        staleTime: 300000 // 5 minutes
      }
    },
    queryCache: new QueryCache({
      onError: error => {
        showToast(`Something went wrong: ${error.message}`, ToastVariant.ERROR);
      }
    })
  });

  return (
    <>
      <ToastContainer theme={isDarkMode ? 'dark' : 'light'} />
      <QueryClientProvider client={queryClient}>
        <MainContainer>
          <TabNavigation />
          <Sidebar />
          <GoogleMaps />
          <ImageGalleryWrapper />
        </MainContainer>
      </QueryClientProvider>
    </>
  );
};

export default App;
