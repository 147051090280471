export enum TabType {
  LIST = 'list',
  MAP = 'map'
}

export enum InputType {
  TAG = 'tag',
  USERNAME = 'username',
  SELECTED_IMAGE_ID = 'selectedImageId',
  NONE = 'none'
}

export enum ToastVariant {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
  WARNING = 'warning'
}

export enum ImageSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large'
}

export enum ParamNames {
  TAG = 'tag',
  USERNAME = 'username',
  VALUE = 'value',
  ID = 'id',
  FLICKR = 'flickr:'
}

export interface ImagePhotoData {
  geo_is_public: 1 | 0;
  ownername: string;
  owner: string;
  tags: string;
  datetaken: string;
  id: string;
  description: string;
  title: string;
  coordinates:
    | {
        lat: number;
        lng: number;
      }
    | undefined;
  url: string;
  flickrUrl: string;
}

export interface UserProfileInfo {
  name: string;
  id: string;
  profileURL: string;
  totalPhotos: number;
}

export interface GalleryImage {
  original: string;
  thumbnail: string;
  id: string;
}

interface FlickrFetchStatus {
  code: number;
  message: string;
  stat: 'ok' | 'fail';
}

export interface FlickUserGetId extends FlickrFetchStatus {
  user: {
    id: string;
    nsid: string;
    username: {
      _content: string;
    };
  };
}

export interface FlickrUserGetInfoResponse extends FlickrFetchStatus {
  person: {
    id: string;
    nsid: string;
    ispro: number;
    is_deleted: number;
    iconserver: string;
    iconfarm: number;
    path_alias: string | null;
    has_stats: number;
    username: {
      _content: string;
    };
    realname: {
      _content: string;
    };
    location: {
      _content: string;
    };
    timezone: {
      label: string;
      offset: string;
      timezone_id: string;
      timezone: number;
    };
    description: {
      _content: string;
    };
    photosurl: {
      _content: string;
    };
    profileurl: {
      _content: string;
    };
    mobileurl: {
      _content: string;
    };
    photos: {
      firstdatetaken: {
        _content: string;
      };
      firstdate: {
        _content: string;
      };
      count: {
        _content: number;
      };
    };
    has_adfree: number;
    has_free_standard_shipping: number;
    has_free_educational_resources: number;
  };
}

export interface FlickrPhotoGetInfoResponse extends FlickrFetchStatus {
  photo: {
    id: string;
    secret: string;
    server: string;
    farm: string;
    dateuploaded: string;
    isfavorite: string;
    license: string;
    safety_level: string;
    rotation: string;
    views: string;
    media: string;
    owner: {
      nsid: string;
      username: string;
      realname: string;
      location: string;
      iconserver: string;
      iconfarm: string;
      path_alias: string;
      gift: {
        gift_eligible: string;
        new_flow: string;
        eligible_durations: string[]; // You might want to change this to the appropriate type
      };
    };
    title: {_content: string};
    description: {_content: string};
    visibility: {
      ispublic: string;
      isfriend: string;
      isfamily: string;
    };
    dates: {
      posted: string;
      taken: string;
      takengranularity: string;
      takenunknown: string;
      lastupdate: string;
    };
    editability: {
      cancomment: string;
      canaddmeta: string;
    };
    publiceditability: {
      cancomment: string;
      canaddmeta: string;
    };
    usage: {
      candownload: string;
      canblog: string;
      canprint: string;
      canshare: string;
    };
    comments: string;
    notes: string; // You might want to change this to the appropriate type
    people: {
      haspeople: string;
    };
    tags: {
      tag: {
        id: string;
        author: string;
        authorname: string;
        raw: string;
        machine_tag: string;
      }[];
    };
    location?: {
      latitude: string;
      longitude: string;
      accuracy: string;
      context: string;
      locality: string;
      county: string;
      region: string;
      country: string;
      neighbourhood: string;
    };
    geoperms?: {
      ispublic: '0' | '1';
      iscontact: string;
      isfriend: string;
      isfamily: string;
    };
    urls: {
      url: {
        type: string;
        _text: string; // You might want to change this to the appropriate type
      };
    };
  };
}

export interface FlickrPhotosSearchResponse extends FlickrFetchStatus {
  photos: {
    page: number;
    pages: number;
    perpage: number;
    total: number;
    photo: {
      ownername: string;
      id: string; // Check if id is present
      owner: string;
      secret: string;
      server?: string;
      farm?: number;
      title: string;
      ispublic?: number;
      isfriend?: number;
      isfamily?: number;
      license?: string;
      datetaken: string;
      description: {
        _content: string;
      };
      pathalias: string;
      datetakengranularity?: number;
      datetakenunknown?: string;
      iconserver?: string;
      iconfarm?: number;
      tags: string;
      latitude: number;
      longitude: number;
      geo_is_public: 1 | 0;
      accuracy?: string;
      context?: number;
      geo_is_contact?: number;
      geo_is_family?: number;
      geo_is_friend?: number;
      place_id?: string;
      woeid?: string;
    }[];
  };
}

export interface ExportedPhotoData {
  page: number;
  pages: number;
  perpage: number;
  total: number;
  photos: Array<ImagePhotoData>;
}

// export interface ApipopularTagsResponse extends Record<string, popularTags> {
//   body: popularTags;
// }

export interface ApiHotTagsResponse extends FlickrFetchStatus {
  count: number;
  hottags: {
    tag: Array<tag>;
  };
  period: 'day' | 'week';
}

interface tag {
  _content: string;
  thm_data: {photos: Array<FlickrPhotosSearchResponse['photos']['photo']>};
}

export interface UserSettings {
  explore_mode: boolean;
}

export interface SearchParameters {
  api_key?: string;
  src?: string;
  page?: number;
  user_id?: string;
  tags?: string[];
  tag_mode?: any;
  text?: any;
  min_upload_date?: any;
  max_upload_date?: any;
  min_taken_date?: any;
  max_taken_date?: any;
  license?: any;
  sort?:
    | 'date-posted-asc'
    | 'date-posted-desc'
    | 'date-taken-asc'
    | 'date-taken-desc'
    | 'interestingness-desc'
    | 'interestingness-asc'
    | 'relevance';
  privacy_filter?: any;
  bbox?: string | null;
  accuracy?: any;
  safe_search?: 1 | 2 | 3;
  content_type?: any;
  content_types?: any;
  video_content_types?: any;
  machine_tags?: any;
  machine_tag_mode?: any;
  group_id?: any;
  contacts?: any;
  woe_id?: any;
  place_id?: any;
  media?: any;
  has_geo?: boolean;
  geo_context?: any;
  lat?: any;
  lon?: any;
  radius?: any;
  radius_units?: any;
  is_commons?: any;
  in_gallery?: any;
  is_getty?: any;
  per_page?: number;
  extras?: string;
  format?: 'json';
}
