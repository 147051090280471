import {toast} from 'react-toastify';
import {ToastVariant} from '@customTypes/app';
import {toastOptions} from '@constants/app';

export const showToast = (
  message: string,
  variant: ToastVariant = ToastVariant.INFO
) => {
  toast(message, {...toastOptions, type: variant});
};
