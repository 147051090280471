import {ToastOptions} from 'react-toastify';

export const toastOptions: ToastOptions = {
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  position: 'bottom-right'
};
