import {FormEvent, FunctionComponent, useRef} from 'react';
import ArrowIconBack from '@components/icons/arrow-back';
import CrossIcon from '@components/icons/cross';
import SearchIcon from '@components/icons/lens';
import {InputType, ToastVariant} from '@customTypes/app';
import {
  setHasGeoTaggedEnabled,
  setInputTag,
  setInputUserName,
  setIsExploreModeEnabled
} from '@store/flickr-slice';

import {useDispatch, useSelector} from 'react-redux';
import {UnknownAction} from '@reduxjs/toolkit';

import styles from '@components/searchbox/searchbar.module.css';
import {selectUserInput} from '@store/flickr-selectors';
import {showToast} from '@utils/show-toast';

interface Props {
  info: {
    type: InputType.USERNAME | InputType.TAG;
    placeholder: string;
  };
}

const Searchbox: FunctionComponent<Props> = props => {
  const {info} = props;
  const userInputRef = useRef<HTMLInputElement>(null);

  const dispatch = useDispatch();

  const userInput = useSelector(selectUserInput);

  const userSubmitHandler = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    if (
      !userInputRef.current ||
      typeof userInputRef.current.value !== 'string' ||
      !userInputRef.current.value.trim().length
    ) {
      showToast('Please enter a valid input', ToastVariant.ERROR);
      return;
    }

    /**
     * Checks if the current user input matches the value of the user input reference.
     * If it does, it means that the user has not changed the input value.
     */
    if (userInputRef.current.value === userInput.value?.toLocaleString()) {
      userInputRef.current.value = '';
      return;
    }

    if (info.type === InputType.USERNAME) {
      dispatch(
        setInputUserName(userInputRef.current.value) as unknown as UnknownAction
      );
      dispatch(setIsExploreModeEnabled(false));
    }

    if (info.type === InputType.TAG) {
      dispatch(setInputTag(userInputRef.current.value));
      dispatch(setHasGeoTaggedEnabled(true));
    }

    userInputRef.current.value = '';
  };
  return (
    <form onSubmit={userSubmitHandler} className={styles.form}>
      <label htmlFor="search">
        <input
          ref={userInputRef}
          required={true}
          placeholder={info.placeholder}
          id="search"
          type="text"></input>
        <div className={styles.icon}>
          <SearchIcon className={styles['search-icon']} />
          <ArrowIconBack className={styles['back-arrow-icon']} />
        </div>
        <button type="reset" className={styles['close-btn']}>
          <CrossIcon />
        </button>
      </label>
    </form>
  );
};

export default Searchbox;
