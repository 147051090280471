import {useQuery} from '@tanstack/react-query';
import {fetchPopularTags} from '@utils/flickr-api';

export const useFetchPopularTags = () => {
  const popularTags = useQuery({
    queryKey: ['tags'],
    queryFn: async () => {
      const tagsResponse = await fetchPopularTags();

      const tags = tagsResponse?.hottags?.tag.map(tag => tag._content);
      return tags ?? [];
    }
  });

  return popularTags;
};
