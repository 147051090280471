import {forwardRef} from 'react';

import styles from './map-canvas.module.css';

const MapCanvas = forwardRef<HTMLDivElement, Record<string, unknown>>(
  function MapCanvas(_, ref) {
    return <div ref={ref} className={styles.map} />;
  }
);

export default MapCanvas;
