import {FunctionComponent, SVGAttributes} from 'react';

interface Props extends Omit<SVGAttributes<SVGSVGElement>, 'viewBox'> {
  direction: 'left' | 'right';
}

const OpenImageGalleryIcon: FunctionComponent<Props> = props => {
  const {direction, ...svgProps} = props;
  const transform =
    direction === 'right' ? 'rotate(60)' : 'rotate(120) scale(1, -1)';

  return (
    <svg
      {...svgProps}
      height="14"
      viewBox="0 -960 960 960"
      width="14"
      transform-origin="center" // eslint-disable-line
      transform={transform}
      style={{marginRight: '0.5rem'}}>
      <path
        color="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        d="M600-80q-127-48-203.5-158T320-484q0-91 36-172.5T458-800H320v-80h280v280h-80v-148q-57 51-88.5 119.5T400-484q0 102 54 187.5T600-167v87Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default OpenImageGalleryIcon;
