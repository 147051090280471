import {Action, createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {initialState} from './flickr-context-provider';
import {InputType, ToastVariant} from '@customTypes/app';
import serializeQuery from '@utils/serialize-query';
import {fetchUserDataByUsername} from '@utils/get-user-info';
import {showToast} from '@utils/show-toast';

interface setInputAction extends Action {
  payload: string;
}

export const setInputUserName = createAsyncThunk(
  'photos/setInputUserName',
  async (username: string) => await fetchUserDataByUsername(username)
);

function isSetInputAction(action: Action): action is setInputAction {
  return action.type.startsWith('photos/setInput');
}

function isEditAction(action: Action): action is setInputAction {
  return (
    action.type.startsWith('photos/deselect') ||
    (isSetInputAction(action) && !action.type.includes('pending'))
  );
}

const flickrSlice = createSlice({
  name: 'photos',
  initialState,
  reducers: {
    setHoveredMarkerId: (state, action) => {
      state.hoveredMarkerId = action.payload;
    },
    setHoveredImageId: (state, action) => {
      state.hoveredImageId = action.payload;
    },

    setTags: (state, action) => {
      state.tags = [...state.tags, ...action.payload].filter(
        (tag, index, tags) => tags.indexOf(tag) === index
      );
    },

    setSelectedImageId: (state, action) => {
      state.selectedImageId = action.payload;
      serializeQuery(state.input, state.selectedImageId);

      if (navigator.clipboard) {
        navigator.clipboard
          .writeText(window.location.href)
          .then(() => {
            // Permission granted
            showToast('Copied URL to clipboard. Share it with your friends!');
          })
          .catch(err => {
            // Permission denied or other error
            console.error('Text Unable to copy text to clipboard:', err);
          });
      }
    },
    setSearchParams: (state, action) => {
      state.searchParameters = {
        ...state.searchParameters,
        ...action.payload
      };
    },

    /**
     * Sets the explore mode enabled state and updates the search parameters accordingly.
     * @param state - The current state.
     * @param action - The action containing the payload.
     */
    setIsExploreModeEnabled: (state, action) => {
      if (action.payload) {
        showToast(
          'Explore Mode is now enabled. You can drag and zoom the map to find photos.'
        );
      } else if (!action.payload && state.isExploreModeEnabled) {
        showToast('Explore Mode is now disabled.');
        state.searchParameters.bbox = null;
      }
      state.isExploreModeEnabled = action.payload;
    },

    setUserSettings: (state, action) => {
      state.userSettings = {
        ...state.userSettings,
        ...action.payload
      };
    },

    setInputTag: (state, action) => {
      const addedTag = action.payload;

      if (!state.input.value) {
        console.warn('Input value is null');
        return;
      }

      state.input = {
        value:
          state.input.type === InputType.TAG
            ? [...new Set(state.input.value.concat(addedTag as string))]
            : [addedTag as string],
        type: InputType.TAG
      };

      state.tags = [...new Set(state.tags.concat(addedTag as string))];
    },
    toggleGalleryVisibility: (state, action) => {
      state.isGalleryVisible = action.payload;
    },

    deselectInputTag: (state, action) => {
      if (!state.input.value) {
        console.warn('Input value is null');
        return;
      }
      // create a new array of strings, that does not contain the deselect tag
      state.input.value = state.input.value.filter(
        tag => tag !== action.payload
      );
    },
    setBbox: (state, action) => {
      state.searchParameters.bbox = action.payload.bbox;
    },
    setHasGeoTaggedEnabled: (state, action) => {
      // eslint-disable-next-line camelcase
      state.searchParameters.has_geo = action.payload;
    },
    setIsDrawerOpen: (state, action) => {
      state.isDrawerOpen = action.payload;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(setInputUserName.fulfilled, (state, action) => {
        if (!action.payload) {
          showToast('User not found', ToastVariant.ERROR);
          return;
        }
        state.input = {
          value: [action.payload.username._content],
          type: InputType.USERNAME
        };

        state.user = action.payload;
      })

      .addMatcher(isEditAction, state => {
        state.selectedImageId = null;
        serializeQuery(state.input, state.selectedImageId);
      })
      // add provide a default case if no other handlers matched
      .addDefaultCase((_, action) => {
        // eslint-disable-next-line no-console
        console.log('Unknown handler', action);
      });
  }
});

// Action creators are generated for each case reducer function
export const {
  setHoveredMarkerId,
  setHoveredImageId,
  setTags,
  setSearchParams,
  setUserSettings,
  deselectInputTag,
  setIsExploreModeEnabled,
  setInputTag,
  setSelectedImageId,
  toggleGalleryVisibility,
  setBbox,
  setHasGeoTaggedEnabled,
  setIsDrawerOpen
} = flickrSlice.actions;

export default flickrSlice.reducer;
