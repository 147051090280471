import {FunctionComponent, PropsWithChildren, useRef, TouchEvent} from 'react';

import cx from 'classnames';
import styles from './drawer-wrapper.module.css';
import {useDispatch, useSelector} from 'react-redux';
import {selectIsDrawerOpen} from '@store/flickr-selectors';
import {setIsDrawerOpen} from '@store/flickr-slice';

const DrawerWrapper: FunctionComponent<PropsWithChildren> = ({children}) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(selectIsDrawerOpen);

  const touchStartY = useRef(0);
  const touchEndY = useRef(0);

  const handleTouchStart = (event: TouchEvent) => {
    touchStartY.current = event.touches[0].clientY;
    touchEndY.current = event.touches[0].clientY;
  };

  const handleTouchMove = (event: TouchEvent) => {
    touchEndY.current = event.touches[0].clientY;
  };

  const handleTouchEnd = () => {
    // Swiped up
    if (touchEndY < touchStartY) {
      // setIsOpen(true);
      dispatch(setIsDrawerOpen(true));
      // Swiped down
    } else if (touchEndY > touchStartY) {
      dispatch(setIsDrawerOpen(false));
    }
  };

  const toggleDrawer = () => {
    dispatch(setIsDrawerOpen(!isOpen));
  };

  return (
    <div
      className={cx(
        styles.drawer,
        isOpen ? styles['is-open'] : styles['is-closed']
      )}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}>
      <div className={styles['drawer-handle']} onClick={toggleDrawer} />
      <div className={styles['drawer-content']}>{children}</div>
    </div>
  );
};

export default DrawerWrapper;
