import ExpandAndFoldIcon from '@components/icons/expand-and-fold-icon/expand-and-fold-icon';
import Searchbox from '@components/searchbox/searchbox';
import UserCard from '@components/user-card/user-card';
import React, {FunctionComponent} from 'react';

import {InputType} from '@customTypes/app';
import {useGetInputType} from '@hooks/use-get-input-type';
import {useIsMobile} from '@hooks/use-is-mobile';
import {selectUser} from '@store/flickr-selectors';
import {useSelector} from 'react-redux';
import styles from './searchfields.module.css';

interface Props {
  showInputFields: boolean;
  setShowInputFields: React.Dispatch<React.SetStateAction<boolean>>;
}

const SearchFields: FunctionComponent<Props> = ({
  showInputFields,
  setShowInputFields
}) => {
  const isMobile = useIsMobile();

  const {isInputTypeUsername} = useGetInputType();

  const user = useSelector(selectUser);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.headline}>
          <h2>People</h2>
          {showInputFields && !isMobile && (
            <ExpandAndFoldIcon
              onClick={() => setShowInputFields(state => !state)}
              showExpanded={showInputFields}
              pointerEvents="visiblePainted"
            />
          )}
        </div>
        <Searchbox
          info={{
            placeholder: 'search flickr profiles',
            type: InputType.USERNAME
          }}
        />

        {isInputTypeUsername && <UserCard userData={user} />}
      </div>
      <div className={styles.container}>
        <div className={styles.headline}>
          <h2>Tags</h2>
        </div>
        <Searchbox
          info={{placeholder: 'search flickr tags', type: InputType.TAG}}
        />
      </div>
    </>
  );
};

export default SearchFields;
