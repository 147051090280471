import {InputType} from '@customTypes/app';
import {selectInputType} from '@store/flickr-selectors';
import {useSelector} from 'react-redux';

export const useGetInputType = () => {
  const type = useSelector(selectInputType); // Replace with your actual selector

  const isInputTypeTag = type === InputType.TAG;
  const isInputTypeUsername = type === InputType.USERNAME;

  return {isInputTypeTag, isInputTypeUsername};
};
