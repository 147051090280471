import {GOOGLE_MAPS_API_KEY, mapOptions} from '@constants/map';
import {GoogleMapsProvider} from '@ubilabs/google-maps-react-hooks';
import {useCallback, useState} from 'react';
import Markers from '@components/map-markers/map-markers';
import MapCanvas from '@components/map-canvas/map-canvas';

const GoogleMaps = () => {
  const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null);

  const mapRef = useCallback((node: HTMLDivElement | null) => {
    node && setMapContainer(node);
  }, []);

  return (
    <GoogleMapsProvider
      googleMapsAPIKey={GOOGLE_MAPS_API_KEY}
      mapContainer={mapContainer}
      mapOptions={mapOptions}
      libraries={['marker']}
      version="beta">
      <MapCanvas ref={mapRef} />

      <Markers />
    </GoogleMapsProvider>
  );
};

export default GoogleMaps;
