import {fetchUserIdByUserName, fetchUserInfoById} from './flickr-api';

export async function fetchUserDataByUsername(username: string) {
  const flickrUser = await fetchUserIdByUserName(username);
  if (!flickrUser) {
    console.warn('User not found');
    return null;
  }

  const {user} = flickrUser;

  const userData = await fetchUserInfoById(user.id);
  if (!userData) {
    console.warn('User data not found');
    return null;
  }

  return userData.person;
}
