import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {useGoogleMap} from '@ubilabs/google-maps-react-hooks';

import parseJSXToHTML from '@utils/parse-to-html';
import MarkerMarkup from '@components/map-markers/marker-markup';
import {ImagePhotoData} from '@customTypes/app';
import {useSelector, useDispatch} from 'react-redux';
import {
  selectHoveredImageId,
  selectIsExploreModeEnabled,
  selectSelectedImageId,
  selectUserInput
} from '@store/flickr-selectors';
import {
  setBbox,
  setHoveredMarkerId,
  setSelectedImageId
} from '@store/flickr-slice';
import {selectPhotos} from '@store/photos-slice';

const hover = (markerView: google.maps.marker.AdvancedMarkerElement) => {
  (markerView.content as HTMLElement).classList.add('hover');
  markerView.element.style.zIndex = '4';
};

const unHover = (markerView: google.maps.marker.AdvancedMarkerElement) => {
  (markerView.content as HTMLElement).classList.remove('hover');
  markerView.element.style.zIndex = '0';
};

const highlightOnImageHover = (
  markerView: google.maps.marker.AdvancedMarkerElement
) => {
  (markerView.content as HTMLElement).classList.add('image-hovered');
  markerView.element.style.zIndex = '3';
};

// If the marker is selected, it should remain on top of the other markers
const removeHighlightOnImageUnHover = (
  markerView: google.maps.marker.AdvancedMarkerElement,
  isSelect: boolean
) => {
  (markerView.content as HTMLElement).classList.remove('image-hovered');
  !isSelect && (markerView.element.style.zIndex = '0');
};

const select = (markerView: google.maps.marker.AdvancedMarkerElement) => {
  if (!markerView) {
    return;
  }
  (markerView.content as HTMLElement).classList.add('image-selected');
  markerView.element.style.zIndex = '5';
};

const unSelect = (markerView: google.maps.marker.AdvancedMarkerElement) => {
  if (!markerView) {
    return;
  }
  (markerView.content as HTMLElement).classList.remove('image-selected');
  markerView.element.style.zIndex = '0';
};

const intersectionObserver = new IntersectionObserver(entries => {
  for (const entry of entries) {
    if (entry.isIntersecting) {
      entry.target.classList.add('drop');
      intersectionObserver.unobserve(entry.target);
    }
  }
});

const Markers: FunctionComponent = () => {
  const dispatch = useDispatch();

  const photos = useSelector(selectPhotos);
  const input = useSelector(selectUserInput);
  const map = useGoogleMap();

  const [isPhotosLoaded, setIsPhotosLoaded] = useState(false);

  const hoveredImageId = useSelector(selectHoveredImageId);

  const selectedImageId = useSelector(selectSelectedImageId);

  const boundsRef = useRef<google.maps.LatLngBounds | null>(null);

  const isExploreModeEnabled = useSelector(selectIsExploreModeEnabled);

  const markersByPhotoId = useRef<
    Record<string, google.maps.marker.AdvancedMarkerElement>
  >({});

  let timeoutId: NodeJS.Timeout | null = null;

  const getBounds = () => {
    const sw = map?.getBounds()?.getSouthWest();
    const ne = map?.getBounds()?.getNorthEast();
    if (!sw || !ne) {
      return;
    }
    return [sw.lng(), sw.lat(), ne.lng(), ne.lat()].toString();
  };

  const [bounds, setBounds] = useState<string | undefined>(getBounds());

  map?.addListener('bounds_changed', () => {
    clearTimeout(timeoutId as NodeJS.Timeout);

    // Set a new timeout to update the map view after 500 milliseconds
    timeoutId = setTimeout(() => {
      setBounds(getBounds());
    }, 500);

    return () => clearTimeout(timeoutId as NodeJS.Timeout);
  });

  // Update search parameters when map bounds change and explore mode is active
  useEffect(() => {
    if (bounds && isExploreModeEnabled) {
      dispatch(setBbox({bbox: bounds}));
    }
  }, [bounds, dispatch, isExploreModeEnabled]);

  enum MarkerSize {
    LARGE = 'large',
    MEDIUM = 'medium',
    SMALL = 'small'
  }
  // Returns the marker's size depending on the zoom level
  const getMarkerSize = (zoom: number): MarkerSize =>
    // eslint-disable-next-line no-nested-ternary
    zoom >= 12
      ? MarkerSize.LARGE
      : zoom >= 6
      ? MarkerSize.MEDIUM
      : MarkerSize.SMALL;

  // Handle hovered image in sidebar
  useEffect(() => {
    if (!hoveredImageId) {
      return;
    }

    const marker = markersByPhotoId.current[hoveredImageId];
    if (marker) {
      highlightOnImageHover(marker);
    }

    return () => {
      if (marker) {
        removeHighlightOnImageUnHover(
          markersByPhotoId.current[hoveredImageId],
          hoveredImageId === selectedImageId
        );
      }
    };
  }, [hoveredImageId, selectedImageId]);

  const addMapMarkers = useCallback(
    async (photosArr: Array<ImagePhotoData>): Promise<void> => {
      const {AdvancedMarkerElement} = (await google.maps.importLibrary(
        'marker'
      )) as google.maps.MarkerLibrary;
      if (!map || !photosArr) {
        return;
      }

      const {LatLngBounds} = (await google.maps.importLibrary(
        'core'
      )) as google.maps.CoreLibrary;

      // Create a LatLngBounds object to fit the map to the markers
      boundsRef.current = new LatLngBounds();

      for (const photo of photosArr) {
        if (photo.coordinates) {
          boundsRef.current.extend(photo.coordinates);
        }

        const markerContent = parseJSXToHTML(
          <MarkerMarkup title={photo.title} src={photo.url} />
        );

        const advancedMarker = new AdvancedMarkerElement({
          map,
          position: photo.coordinates,
          content: markerContent.cloneNode(true) as HTMLElement,
          title: photo.title
        });

        const element = advancedMarker.element;
        element.id = photo.id;
        element.style.zIndex = '0';

        element.addEventListener('mouseenter', () => {
          dispatch(setHoveredMarkerId(element.id));
          hover(advancedMarker);
        });

        element.addEventListener('click', () => {
          dispatch(setSelectedImageId(element.id));
        });

        element.addEventListener('mouseleave', () => {
          dispatch(setHoveredMarkerId(null));
          unHover(advancedMarker);
        });

        const content = advancedMarker.content as HTMLElement;
        content.style.opacity = '0';
        content.addEventListener('animationend', () => {
          content.classList.remove('drop');
          content.style.opacity = '1';
        });

        const time = Math.random();
        content.style.setProperty('--delay-time', time + 's');

        intersectionObserver.observe(content);

        content.classList.add(getMarkerSize(map?.getZoom() || 0));

        markersByPhotoId.current[photo.id] = advancedMarker;
      }
      !isExploreModeEnabled && map?.fitBounds(boundsRef.current);
      setIsPhotosLoaded(true);
    },
    [map, isExploreModeEnabled]
  ); // add other dependencies if needed
  // Update store marker size depending on the zoom level
  useEffect(() => {
    const zoomListener = map?.addListener('zoom_changed', () => {
      const zoom = map?.getZoom() || 0;
      const newMarkerSize = getMarkerSize(zoom);

      for (const marker of Object.values(markersByPhotoId.current)) {
        Object.values(MarkerSize).forEach(markerSize => {
          (marker.content as SVGElement).classList.toggle(
            markerSize,
            markerSize === newMarkerSize
          );
        });
      }
    });

    return () => {
      zoomListener?.remove();
    };
  }, [MarkerSize, getMarkerSize, map]);

  /**
   * useEffect hook that adds map markers for photos with valid coordinates.
   * It checks if the map and photos are available, and filters out photos that are already in markersByPhotoId and have valid coordinates.
   * If there are photos to add, it calls the addMapMarkers function.
   *
   * @param {Array<Photo>} photos - The array of photos to add markers for.
   */
  useEffect(() => {
    if (map && photos && photos.length > 0) {
      // Check if photo is already in markersByPhotoId and has valid coordinates
      const photosToAdd = photos.filter(
        photo =>
          !markersByPhotoId.current[photo.id] &&
          photo.coordinates?.lat &&
          photo.coordinates?.lng
      );

      if (photosToAdd.length === 0) {
        return;
      }
      addMapMarkers(photosToAdd);
    }
  }, [addMapMarkers, map, photos]);

  /**
   * useEffect hook that removes all markers if the input has changed.
   * It logs the removal of markers and cleans up the markersByPhotoId object.
   *
   * @param {string} input.value - The input value that triggers the effect.
   */
  useEffect(
    () => () => {
      for (const marker of Object.values(markersByPhotoId.current)) {
        marker.map = null;
      }
      markersByPhotoId.current = {};
    },
    [input.value]
  );

  /**
   * useEffect hook that handles selecting and panning to a marker on the map.
   * It is triggered when the selectedImageId or isPhotosLoaded changes.
   * @returns A cleanup function that unselects the marker.
   */
  useEffect(() => {
    if (!selectedImageId || !isPhotosLoaded) {
      return;
    }
    const marker = markersByPhotoId.current[selectedImageId];

    if (marker) {
      select(marker);
      if (map && marker.position) {
        map.panTo(marker.position);
      }
    }

    return () => {
      if (marker) {
        unSelect(markersByPhotoId.current[selectedImageId]);
      }
    };
  }, [selectedImageId, isPhotosLoaded, map]);

  return null;
};

export default Markers;
