import {useEffect, useState} from 'react';

const useDarkMode = (): [boolean, (newState: boolean) => void] => {
  // Get the default mode based on the user's browser preference
  const prefersDarkMode = window.matchMedia(
    '(prefers-color-scheme: dark)'
  ).matches;

  // Get the user's preference from localStorage
  const localStorageDarkMode = localStorage.getItem('darkMode');

  // Initialize the state with the user's preference (if available) or the default value from browser
  const initialSetting = localStorageDarkMode
    ? JSON.parse(localStorageDarkMode)
    : prefersDarkMode;

  // Use state to keep track of the mode throughout the app
  const [isDarkMode, setIsDarkMode] = useState<boolean>(initialSetting);

  // Custom set function to update the state
  const setState = (newState: boolean) => setIsDarkMode(() => newState);

  // Update localStorage whenever isDarkMode changes
  useEffect(() => {
    // Use localStorage to persist the user's preference (if available)
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
    document.body.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    // Function to handle changes in the user's color scheme preference
    const handleColorSchemeChange = (event: {matches: boolean}) => {
      setIsDarkMode(event.matches);
    };

    // Subscribe to changes in the user's color scheme preference
    const darkModeMediaQuery = window.matchMedia(
      '(prefers-color-scheme: dark)'
    );
    darkModeMediaQuery.addEventListener('change', handleColorSchemeChange);

    // Clean up the event listener when the component unmounts
    return () => {
      darkModeMediaQuery.removeEventListener('change', handleColorSchemeChange);
    };
  }, []);

  return [isDarkMode, setState];
};

export default useDarkMode;
