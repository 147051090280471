import {FunctionComponent} from 'react';

import styles from './toggle-switch.module.css';

interface Props {
  label: string;
  disabled?: boolean;
  checked: boolean;
  onToggle: () => void;
}

const ToggleSwitch: FunctionComponent<Props> = ({
  label,
  onToggle,
  disabled,
  checked
}) => (
  <label className={styles.switch}>
    {label}
    <input
      type="checkbox"
      onChange={onToggle}
      checked={checked}
      disabled={disabled}
    />
    <span className={styles.slider}></span>
  </label>
);

export default ToggleSwitch;
