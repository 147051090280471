import {FunctionComponent} from 'react';
import {FlickrUserGetInfoResponse} from '@customTypes/app';

import styles from './user-card.module.css';
interface Props {
  userData: FlickrUserGetInfoResponse['person'] | null;
}

const UserCard: FunctionComponent<Props> = ({userData}) => {
  if (!userData) {
    return null;
  }

  return (
    <div className={styles.card}>
      <div className={styles['left-part']}>
        <div className={styles['user-name']}>
          <p className={styles.name}>{userData.username._content}</p>
        </div>
        <div className={styles['user-position']}>
          <p className={styles.position}>
            {userData.photos.count._content} photos
          </p>
          <a
            href={userData.profileurl._content}
            target="_blank"
            className={styles.link}
            rel="noreferrer">
            <img src="https://www.flickr.com/images/opensearch-flickr-logo.png" />
          </a>
        </div>
      </div>
      <div className={styles['right-part']}>
        <img
          src={`https://live.staticflickr.com/3470/buddyicons/${userData.id}_l.jpg`}
          className={styles['user-photo']}
        />
      </div>
    </div>
  );
};
export default UserCard;
