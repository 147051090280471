import DrawerWrapper from '@components/drawer-wrapper/drawer-wrapper';
import ImageList from '@components/image-list/image-list';
import PhotosTitle from '@components/photos-title/photos-title';
import SearchFields from '@components/searchfields/searchfields';
import Tags from '@components/tags/tags';

import {FunctionComponent, memo, useEffect, useMemo, useState} from 'react';
import {ImageSize} from '@customTypes/app';
import {useInfinitePhotos} from '@hooks/use-infinite-photos';
import {useIsMobile} from '@hooks/use-is-mobile';
import {useInView} from 'react-intersection-observer';
import {useDispatch, useSelector} from 'react-redux';

import {
  selectHasGeoTaggedEnabled,
  selectSelectedImageId
} from '@store/flickr-selectors';

import {setSelectedImageId} from '@store/flickr-slice';
import {selectPhotos, setPhotoApiData} from '@store/photos-slice';

import styles from './sidebar.module.css';

/**
 * Sidebar component that displays a list of photos.
 *
 * @remarks
 * This component uses the `useInfinitePhotos` hook to fetch and display photos.
 *
 * @returns JSX.Element
 */
const Sidebar: FunctionComponent = () => {
  const dispatch = useDispatch();

  const selectedImageId = useSelector(selectSelectedImageId);
  const hasGeo = useSelector(selectHasGeoTaggedEnabled);

  const [showInputFields, setShowInputFields] = useState<boolean>(true);
  const [imageSize, setImageSize] = useState<ImageSize>(ImageSize.SMALL);

  /**
   * This hook is used to detect when the user has scrolled to the image with the inViewRef attached.
   */
  const {ref: inViewRef, inView} = useInView({
    threshold: 0
  });

  const {
    data: photoApiData,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetching
  } = useInfinitePhotos();

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    dispatch(setPhotoApiData(photoApiData));
  }, [dispatch, isSuccess, photoApiData]);

  useEffect(() => {
    if (inView && hasNextPage && !isFetching) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetching, fetchNextPage]);

  const photos = useSelector(selectPhotos);

  /**
   * Filters the photos based on the presence of geo information.
   * If `hasGeo` is false, all photos are returned.
   * If `hasGeo` is true, only photos with public geo information are returned.
   * @param photos - The array of photos to filter.
   * @param hasGeo - A boolean indicating whether to filter photos with geo information.
   * @returns The filtered array of photos.
   */
  const filteredPhotos = useMemo(() => {
    if (!photos) {
      return null;
    }

    return photos.filter(photo => {
      if (!hasGeo) {
        return true;
      }
      return photo.geo_is_public;
    });
  }, [photos, hasGeo]);

  const onImageSelect = (imageId: string) => {
    if (selectedImageId === imageId) {
      dispatch(setSelectedImageId(null));
      return;
    }
    dispatch(setSelectedImageId(imageId));

    return;
  };

  const isMobile = useIsMobile();

  useEffect(() => {
    if (!imageSize) {
      return;
    }

    document.documentElement.style.setProperty(
      '--box-size',
      (() => {
        switch (imageSize) {
          case ImageSize.SMALL:
            return '4';
          case ImageSize.MEDIUM:
            return '2';
          case ImageSize.LARGE:
            return '1';
          default:
            return '1';
        }
      })()
    );
  }, [imageSize]);

  const SearchFieldComponent = memo(function drawerComponent() {
    return !isMobile ? (
      <>
        <SearchFields
          showInputFields={showInputFields}
          setShowInputFields={setShowInputFields}
        />
        <Tags />
      </>
    ) : (
      <DrawerWrapper>
        <SearchFields
          showInputFields={showInputFields}
          setShowInputFields={setShowInputFields}
        />
        <Tags />
      </DrawerWrapper>
    );
  });

  return (
    <aside className={styles.sidebar}>
      {showInputFields && <SearchFieldComponent />}

      <div className={styles['photos-container']}>
        <PhotosTitle
          imageSize={imageSize}
          setImageSize={setImageSize}
          showInputFields={showInputFields}
          setShowInputFields={setShowInputFields}
        />
        {filteredPhotos && (
          <ImageList
            photos={filteredPhotos}
            onImageSelect={onImageSelect}
            isFetching={isFetching}
            hasNextPage={hasNextPage}
            inViewRef={inViewRef}
          />
        )}
      </div>
    </aside>
  );
};

export default Sidebar;
