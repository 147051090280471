import {
  FlickrPhotoGetInfoResponse,
  FlickrPhotosSearchResponse
} from '@customTypes/app';

const getPhotoUrl = (id: string, secret: string, server?: string) =>
  `https://live.staticflickr.com/${server}/${id}_${secret}.jpg`;

export const transformPhotoData = (
  photos: FlickrPhotosSearchResponse['photos']
) => {
  const convertedPhotos = photos.photo.map(photo => ({
    geo_is_public: photo.geo_is_public,
    ownername: photo.ownername,
    owner: photo.owner,
    tags: photo.tags,
    datetaken: photo.datetaken,
    title: photo.title,
    coordinates: photo.geo_is_public
      ? {
          lat: Number(photo.latitude),
          lng: Number(photo.longitude)
        }
      : // eslint-disable-next-line no-undefined
        undefined,
    url: getPhotoUrl(photo.id, photo.secret, photo.server),
    id: photo.id,
    description: photo.description._content,
    flickrUrl: `https://www.flickr.com/photos/${photo.owner}/${photo.id}`
  }));

  return convertedPhotos;
};

export function convertPhoto(inputObject: FlickrPhotoGetInfoResponse['photo']) {
  try {
    // Extracting properties from the input object
    const {
      id,
      owner: {nsid: owner, username: ownername, path_alias},
      secret,
      server,
      farm,
      title: {_content: title},
      visibility: {ispublic, isfriend, isfamily},
      license,
      description,
      dates: {taken, takengranularity, takenunknown},
      tags: {tag},
      urls: {url},
      media
    } = inputObject;

    // Extracting location properties from location
    const {latitude, longitude, accuracy, context} = inputObject.location || {
      latitude: null,
      longitude: null,
      accuracy: null,
      context: null
    };

    // Extracting geoperms properties from geoperms
    const {
      ispublic: geo_is_public,
      iscontact: geo_is_contact,
      isfriend: geo_is_friend,
      isfamily: geo_is_family
    } = inputObject.geoperms || {
      ispublic: null,
      iscontact: null,
      isfriend: null,
      isfamily: null
    };

    // Extracting tag content from tags
    const tagsContent = tag.map(({raw}: {raw: string}) => raw).join(' ');

    // Extracting URL content from urls
    // const photoPageUrl = url.length > 0 ? url[0]._content : "";
    const photoPageUrl = url._text || '';

    // Constructing the transformed object
    const transformedObject = {
      id,
      owner,
      secret,
      server,
      farm,
      title,
      ispublic,
      isfriend,
      isfamily,
      license,
      path_alias,
      description: {_content: description._content},
      datetaken: taken,
      datetakengranularity: takengranularity,
      datetakenunknown: takenunknown,
      ownername,
      iconserver: '0', // Assuming a default value, update as needed
      iconfarm: 0, // Assuming a default value, update as needed
      tags: tagsContent,
      latitude,
      longitude,
      accuracy,
      context,
      place_id: '',
      woeid: '2987990', // Assuming a default value, update as needed
      geo_is_public,
      geo_is_contact,
      geo_is_friend,
      geo_is_family,
      urls: {photoPage: photoPageUrl},
      media
    };

    return transformedObject;
  } catch (error) {
    console.log('bonk', error);
  }
}
