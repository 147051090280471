import {ImagePhotoData} from '@customTypes/app';
import {createSlice, createEntityAdapter, Action} from '@reduxjs/toolkit';
import {combinePagesOfPhotos} from '@utils/combine-arrays-of-photos';
import {transformPhotoData} from '@utils/transform-photos-data';

export type PhotosSlice = {
  photoDataFromAPI: ImagePhotoData[] | null;
};

const flickrPhotosAdapter = createEntityAdapter({
  selectId: (image: ImagePhotoData) => {
    return image.id;
  }
});

const initialState = flickrPhotosAdapter.getInitialState();

function isUpdatedInputOrSearchParams(action: Action) {
  return (
    action.type.startsWith('photos/deselect') ||
    action.type.startsWith('photos/setInput') ||
    action.type.startsWith('photos/setSearchParams')
  );
}

const photosSlice = createSlice({
  name: 'flickrPhotos',
  initialState,
  reducers: {
    setPhotoApiData: (state, action) => {
      const transformedPhotos = combinePagesOfPhotos(
        action.payload.pages,
        transformPhotoData
      );

      flickrPhotosAdapter.addMany(state, transformedPhotos);
    }
  },
  extraReducers: builder => {
    builder.addMatcher(isUpdatedInputOrSearchParams, state => {
      flickrPhotosAdapter.removeAll(state);
    });
  }
});

export const {setPhotoApiData} = photosSlice.actions;

export const {selectAll: selectPhotos, selectEntities} =
  flickrPhotosAdapter.getSelectors((state: any) => {
    return state.flickrPhotos;
  });

export default photosSlice.reducer;
