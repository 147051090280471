const FLICKR_API_KEY = import.meta.env.VITE_FLICKR_API_KEY;

if (!FLICKR_API_KEY) {
  throw new Error('Missing Flickr API key');
}

export const flickrAPIOptions = {
  src: 'loc.alize.us',
  format: 'json',
  nojsoncallback: 1,
  api_key: import.meta.env.VITE_FLICKR_API_KEY // eslint-disable-line camelcase
};
