import ImageContainer from '@components/image-container/image-container';
import {ImagePhotoData} from '@customTypes/app';
import LoadingSpinner from '@components/loading-spinner/loading-spinner';

import styles from './image-list.module.css';

interface ImageListProps {
  photos: ImagePhotoData[];
  onImageSelect: (id: string) => void;
  isFetching: boolean;
  hasNextPage: boolean;
  inViewRef: (node?: Element | null | undefined) => void;
}

const ImageList = ({
  photos,
  onImageSelect,
  isFetching,
  hasNextPage,
  inViewRef
}: ImageListProps) => (
  <div className={styles['image-container']}>
    {photos.map((image, index) => (
      <ImageContainer
        key={image.id + index}
        image={image}
        ref={photos && photos?.length - 1 === index ? inViewRef : null}
        onClick={() => onImageSelect(image.id)}
      />
    ))}
    {isFetching && <LoadingSpinner />}

    {!hasNextPage && !isFetching && (
      <p className={styles['list-end']}>
        You have reached the end of the image stream for this search
      </p>
    )}
  </div>
);

export default ImageList;
