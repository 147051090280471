import {useInfiniteQuery} from '@tanstack/react-query';
import {convertPhoto} from '@utils/transform-photos-data';
import {fetchPhoto, fetchPhotos} from '@utils/flickr-api';
import {useSelector} from 'react-redux';
import {
  selectSearchParams,
  selectSelectedImageId,
  selectUserInput
} from '@store/flickr-selectors';

export const useInfinitePhotos = () => {
  const searchParameters = useSelector(selectSearchParams);
  const input = useSelector(selectUserInput);
  const selectedImageId = useSelector(selectSelectedImageId);

  const infinitePhotos = useInfiniteQuery({
    queryKey: ['projects', searchParameters, input.value],
    retry: false, // By default, TanStack Query will automatically retry 3 times to refetch a query if an error occurs.
    queryFn: async ({pageParam}: {pageParam?: number}) => {
      const photos = await fetchPhotos(
        {...searchParameters, page: pageParam},
        input
      );

      if (pageParam === 1 && selectedImageId) {
        const photo = await fetchPhoto(selectedImageId);

        return {
          ...photos,
          photo: [photo && convertPhoto(photo), ...photos.photo]
        };
      }

      return photos;
    },

    getNextPageParam: data => {
      if (data && data.page < data.pages) {
        return data.page + 1;
      }
      return null;
    },
    initialPageParam: 1
  });

  return infinitePhotos;
};
