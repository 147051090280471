import { PropsWithChildren, SVGAttributes, forwardRef } from "react";

export interface SVGIconProps extends SVGAttributes<SVGSVGElement> {
  title?: string;
}

import styles from "./svg-icon.module.css";

const SVGIcon = forwardRef<SVGSVGElement, PropsWithChildren<SVGIconProps>>(
  (props, ref) => {
    const { children, title, ...svgProps } = props;

    return (
      <svg
        pointerEvents="none"
        className={styles.container}
        viewBox="0 0 24 24"
        focusable={false}
        aria-hidden={true}
        ref={ref}
        height="1em"
        width="1em"
        fill="currentColor"
        {...svgProps}
      >
        {Boolean(title) && <title>{title}</title>}
        {children}
      </svg>
    );
  }
);

export default SVGIcon;
