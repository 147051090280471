import React, {
  ChangeEvent,
  FunctionComponent,
  useEffect,
  useRef,
  useState
} from 'react';
import SettingsIcon from '@components/icons/settings-icon';
import RadioButtons from '@components/radio-buttons/radio-buttons';
import ToggleSwitch from '@components/toggle-switch/toggle-switch';
import useDarkMode from '@hooks/use-dark-mode';
import {ImageSize, InputType} from '@customTypes/app';

import styles from './search-settings.module.css';
import {useSelector, useDispatch} from 'react-redux';
import {
  setHasGeoTaggedEnabled,
  setIsExploreModeEnabled,
  setSearchParams
} from '@store/flickr-slice';
import {
  selectHasGeoTaggedEnabled,
  selectIsExploreModeEnabled
} from '@store/flickr-selectors';

interface Props {
  imageSize: ImageSize;
  setImageSize: React.Dispatch<React.SetStateAction<ImageSize>>;
}

const SearchSettings: FunctionComponent<Props> = ({
  imageSize,
  setImageSize
}) => {
  const [showSettings, toggleShowSetting] = useState(false);
  const dispatch = useDispatch();

  const [isDarkMode, setIsDarkMode] = useDarkMode();

  const hasGeo = useSelector(selectHasGeoTaggedEnabled);

  const sort = useSelector((state: any) => state.flickr.searchParameters.sort);

  const inputType = useSelector((state: any) => state.flickr.input.type);

  const isExploreModeEnabled = useSelector(selectIsExploreModeEnabled);

  // Fix not a single source of truth
  const settingsRef = useRef<HTMLDivElement>(null);

  // Fix this
  const handleClickOutside = (event: MouseEvent) => {
    const settingsIcon = document.getElementById('settings-icon');
    if (settingsIcon && settingsIcon.contains(event.target as Node)) {
      return;
    }
    if (
      settingsRef.current &&
      !settingsRef.current.contains(event.target as Node)
    ) {
      toggleShowSetting(false);
    }
  };

  // Fetch all popular tags an display them in the sidebar
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
  }, []);

  // Todo: Implement more search settings
  return (
    <>
      <SettingsIcon
        id="settings-icon"
        className={styles['tune-icon']}
        pointerEvents="visiblePainted"
        onClick={() => toggleShowSetting(state => !state)}
      />
      {showSettings && (
        <div ref={settingsRef} className={styles.settings}>
          <ToggleSwitch
            label="Dark mode"
            onToggle={() => setIsDarkMode(!isDarkMode)}
            checked={isDarkMode || false}
          />
          <ToggleSwitch
            label="Show only geo-tagged photos"
            onToggle={() => dispatch(setHasGeoTaggedEnabled(!hasGeo))}
            checked={hasGeo ?? false}
            disabled={inputType !== InputType.USERNAME}
          />
          <ToggleSwitch
            label="Enable Explore Mode"
            onToggle={() =>
              dispatch(setIsExploreModeEnabled(!isExploreModeEnabled))
            }
            checked={isExploreModeEnabled}
            disabled={inputType === 'username'}
          />
          <RadioButtons
            checked={sort}
            description="Sort by"
            values={['interestingness-desc', 'date-posted-desc', 'relevance']}
            handleChange={(e: ChangeEvent) => {
              if (!(e.target instanceof HTMLInputElement)) {
                return;
              }
              dispatch(
                setSearchParams({
                  sort: e.target.value as typeof sort
                })
              );
            }}
          />

          <RadioButtons
            description="Image Size"
            checked={imageSize}
            values={Object.values(ImageSize)}
            handleChange={(e: ChangeEvent) =>
              setImageSize((e.target as HTMLInputElement).value as ImageSize)
            }
          />
        </div>
      )}
    </>
  );
};

export default SearchSettings;
