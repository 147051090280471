import {FunctionComponent} from 'react';
import cx from 'classnames';

import OpenImageGalleryIcon from '@components/icons/open-image-gallery-icon/open-image-gallery-icon';

import styles from '@components/toggle-gallery-button/toggle-gallery-button.module.css';
import {toggleGalleryVisibility} from '@store/flickr-slice';
import {useDispatch, useSelector} from 'react-redux';
import {selectIsGalleryVisible} from '@store/flickr-selectors';

interface Props {
  className?: string;
}

const ToggleGalleryButton: FunctionComponent<Props> = ({className}) => {
  const isGalleryVisible = useSelector(selectIsGalleryVisible);
  const direction = isGalleryVisible ? 'left' : 'right';
  const dispatch = useDispatch();

  return (
    <button
      className={cx(styles['gallery-toggle-button'], className)}
      onClick={() => dispatch(toggleGalleryVisibility(!isGalleryVisible))}>
      <OpenImageGalleryIcon direction={direction} />
      {isGalleryVisible ? 'Close gallery' : 'view in fullscreen gallery'}
    </button>
  );
};

export default ToggleGalleryButton;
