import React from 'react';
import ExpandAndFoldIcon from '@components/icons/expand-and-fold-icon/expand-and-fold-icon';
import SearchSettings from '@components/search-settings/search-settings';

import {ImageSize} from '@customTypes/app';
import {useIsMobile} from '@hooks/use-is-mobile';

import styles from './photos-title.module.css';

interface PhotosTitleProps {
  imageSize: ImageSize;
  setImageSize: React.Dispatch<React.SetStateAction<ImageSize>>;
  showInputFields: boolean;
  setShowInputFields: React.Dispatch<React.SetStateAction<boolean>>;
}

const PhotosTitle = ({
  imageSize,
  setImageSize,
  showInputFields,
  setShowInputFields
}: PhotosTitleProps) => {
  const isMobile = useIsMobile();
  return (
    <div className={styles['photos-title']}>
      <h2>Photos</h2>
      <SearchSettings imageSize={imageSize} setImageSize={setImageSize} />

      {!showInputFields && !isMobile && (
        <ExpandAndFoldIcon
          onClick={() => setShowInputFields(state => !state)}
          showExpanded={showInputFields}
          pointerEvents="visiblePainted"
          className={styles.tilted}
        />
      )}
    </div>
  );
};

export default PhotosTitle;
