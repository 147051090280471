import {createSelector} from '@reduxjs/toolkit';
import {State} from './flickr-context-provider';

const selectState = (state: State) => state.flickr;

export const selectHoveredMarkerId = createSelector(
  selectState,
  state => state.hoveredMarkerId
);

export const selectUser = createSelector(selectState, state => state.user);

export const selectIsExploreModeEnabled = createSelector(
  selectState,
  state => state.isExploreModeEnabled
);

export const selectHoveredImageId = createSelector(
  selectState,
  state => state.hoveredImageId
);

export const selectTags = createSelector(selectState, state => state.tags);

export const selectInputType = createSelector(
  selectState,
  state => state.input.type
);

export const selectSearchParams = createSelector(
  selectState,
  state => state.searchParameters
);

export const selectUserSettings = createSelector(
  selectState,
  state => state.userSettings
);

export const selectUserInputType = createSelector(
  selectState,
  state => state.input.type
);

export const selectUserInput = createSelector(
  selectState,
  state => state.input
);

export const selectSelectedImageId = createSelector(
  selectState,
  state => state.selectedImageId
);

export const selectHasGeoTaggedEnabled = createSelector(
  selectState,
  state => state.searchParameters.has_geo
);

export const selectIsGalleryVisible = createSelector(
  selectState,
  state => state.isGalleryVisible
);

export const selectIsDrawerOpen = createSelector(
  selectState,
  state => state.isDrawerOpen
);
