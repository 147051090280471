import axios from 'axios';
import {
  ApiHotTagsResponse,
  FlickUserGetId,
  FlickrPhotoGetInfoResponse,
  FlickrPhotosSearchResponse,
  FlickrUserGetInfoResponse,
  InputType,
  SearchParameters
} from '@customTypes/app';
import {flickrAPIOptions} from '@constants/flickr-api-config';
import {UserInputState} from '@customTypes/user-input';

const MAX_TAGS = 20;

const BASE_URL = 'https://api.flickr.com/services/rest';

export const fetchPhotos = async (
  params: SearchParameters,
  input: UserInputState
) => {
  const stringifiedValue = input.value?.join(', ') ?? '';

  const searchArgs = {
    ...params,
    ...(input && input.type === InputType.TAG && {tags: stringifiedValue}),
    ...(input &&
      input.type === InputType.USERNAME && {username: stringifiedValue})
  };

  // Todo: Fix this (boolean is not allowed)
  if (!searchArgs.has_geo) {
    delete searchArgs.has_geo;
  }

  try {
    const {data} = await axios.get<FlickrPhotosSearchResponse>(BASE_URL, {
      params: {
        method: 'flickr.photos.search',
        ...flickrAPIOptions,
        ...searchArgs
      }
    });
    if (data.stat === 'fail') {
      throw new Error(data.message);
    }

    return data.photos;
  } catch (error) {
    console.error(
      'An error occurred while fetching data from Flickr API:',
      error
    );
    throw error;
  }
};

export const fetchPopularTags = async () => {
  try {
    const {data} = await axios.get<ApiHotTagsResponse>(BASE_URL, {
      params: {
        method: 'flickr.tags.getHotList',
        period: 'week',
        count: MAX_TAGS,
        ...flickrAPIOptions
      }
    });

    if (data.stat === 'fail') {
      throw new Error(data.message);
    }

    return data;
  } catch (error) {
    console.log('bonk', error);
  }
};

export const fetchPhoto = async (id: string) => {
  try {
    const {data} = await axios.get<FlickrPhotoGetInfoResponse>(BASE_URL, {
      params: {
        method: 'flickr.photos.getInfo',
        photo_id: id,
        ...flickrAPIOptions
      }
    });

    if (data.stat === 'fail') {
      throw new Error(data.message);
    }

    return data.photo;
  } catch (error) {
    console.log('Error:', error);
  }
};

export const fetchUserIdByUserName = async (username: string) => {
  try {
    const {data} = await axios.get<FlickUserGetId>(BASE_URL, {
      params: {
        method: 'flickr.people.findByUsername',
        username,
        ...flickrAPIOptions
      }
    });

    if (data.stat === 'fail') {
      throw new Error(data.message);
    }

    return data;
  } catch (error) {
    console.log('Error:', error);
  }
};

export const fetchUserInfoById = async (user_id: string) => {
  try {
    const {data} = await axios.get<FlickrUserGetInfoResponse>(BASE_URL, {
      params: {
        method: 'flickr.people.getInfo',
        user_id,
        ...flickrAPIOptions
      }
    });

    if (data.stat === 'fail') {
      throw new Error(data.message);
    }

    return data;
  } catch (error) {
    console.log('Error:', error);
  }
};
