import {renderToString} from 'react-dom/server';
import {JSX} from 'react';

const parseJSXToHTML = (jsxComponent: JSX.Element): HTMLElement => {
  const parser = new DOMParser();
  const stringifiedComponent = renderToString(jsxComponent);

  // Parse the stringified component to HTML
  // Remove the body tag because it's not needed
  const doc = parser.parseFromString(stringifiedComponent, 'text/html').body
    .firstChild as HTMLElement;

  return doc;
};

export default parseJSXToHTML;
