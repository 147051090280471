import {ImagePhotoData} from '@customTypes/app';

export const combinePagesOfPhotos = (
  photos: Array<any>,
  transformingFunction: (photos: any) => ImagePhotoData[]
) => {
  const combinedPhotos = photos.reduce((accumulator: ImagePhotoData[], arr) => {
    const transformedPhotos = transformingFunction(arr);
    accumulator.push(...transformedPhotos);
    return accumulator;
  }, []);

  return combinedPhotos;
};
