import {
  useState,
  Children,
  cloneElement,
  ReactElement,
  FunctionComponent
} from 'react';

import {TabType} from '@customTypes/app';

import styles from '@components/tab-navigation/tab-navigation.module.css';

interface TabProps {
  label: TabType;
  isActive?: boolean;
  onClick?: () => void;
}

const Tab: FunctionComponent<TabProps> = ({label, isActive, onClick}) => (
  <button
    className={isActive ? styles.active : ''}
    onClick={onClick}
    role="tab">
    {label}
  </button>
);

interface TabsProps {
  children: ReactElement<TabProps>[];
  setTab: (tab: TabType) => void;
  activeTab: TabType;
}

const Tabs: FunctionComponent<TabsProps> = ({children, setTab, activeTab}) => (
  <div className={styles['tab-navigation']} data-tab={activeTab}>
    {Children.map(children, child =>
      cloneElement(child, {
        isActive: child.props.label === activeTab,

        onClick: () => setTab(child.props.label)
      })
    )}
  </div>
);

/**
 * Renders a tab navigation component with two tabs: "List" and "Map".
 * @returns A React function component.
 */
const TabNavigation: FunctionComponent = () => {
  const [tab, setTab] = useState<TabType>(TabType.LIST);

  return (
    <Tabs activeTab={tab} setTab={setTab}>
      <Tab label={TabType.LIST} />
      <Tab label={TabType.MAP} />
    </Tabs>
  );
};

export default TabNavigation;
