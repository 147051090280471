import {ChangeEvent, FunctionComponent} from 'react';

import styles from './radio-buttons.module.css';

interface Props {
  checked: string;
  handleChange: (e: ChangeEvent) => void;
  values: string[];
  description: string;
}

const RadioButtons: FunctionComponent<Props> = ({
  checked,
  handleChange,
  values,
  description
}) => (
  <div className={styles['radio-group']} role="radiogroup">
    <span>{description}</span>
    {values.map(value => (
      <label key={value}>
        <input
          type="radio"
          id={value}
          value={value}
          checked={checked === value}
          onChange={handleChange}
        />
        {value}
      </label>
    ))}
  </div>
);

export default RadioButtons;
