import React, {FunctionComponent, useEffect, useMemo, useRef} from 'react';
import ImageGallery from 'react-image-gallery';

import {GalleryImage} from '@customTypes/app';
import ToggleGalleryButton from '@components/toggle-gallery-button/toggle-gallery-button';

import 'react-image-gallery/styles/css/image-gallery.css';
import {selectPhotos} from '@store/photos-slice';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectIsGalleryVisible,
  selectSelectedImageId
} from '@store/flickr-selectors';
import {toggleGalleryVisibility} from '@store/flickr-slice';

import styles from './image-gallery-wrapper.module.css';

const ImageGalleryWrapper: FunctionComponent = () => {
  const photos = useSelector(selectPhotos);
  const selectedImageId = useSelector(selectSelectedImageId);
  const isGalleryVisible = useSelector(selectIsGalleryVisible);

  const ImageGalleryRef = useRef<ImageGallery>(null);

  const dispatch = useDispatch();

  const images: GalleryImage[] = useMemo(
    () =>
      photos?.map(photo => ({
        original: photo.url,
        thumbnail: photo.url,
        id: photo.id,
        description: photo.description,
        originalAlt: photo.title,
        thumbnailAlt: photo.title,
        originalTitle: photo.title,
        thumbnailTitle: photo.title
      })) || [],
    [photos]
  );

  useEffect(() => {
    if (!selectedImageId) {
      return;
    }
    if (ImageGalleryRef.current) {
      const currentIndex =
        images?.findIndex(photo => photo.id === selectedImageId) || 0;
      ImageGalleryRef.current.slideToIndex(currentIndex);
    }
  }, [selectedImageId, isGalleryVisible, images]);

  const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
    const imageGallery = document.querySelector('.image-gallery');

    if (imageGallery && imageGallery.contains(event.target as Node)) {
      return;
    }
    dispatch(toggleGalleryVisibility(false));
  };

  return (
    isGalleryVisible && (
      <div
        className={styles['image-gallery-overlay']}
        onClick={handleClickOutside}>
        <ToggleGalleryButton className={styles['gallery-close-info']} />
        <ImageGallery
          ref={ImageGalleryRef}
          items={images}
          showFullscreenButton={false}
          showPlayButton={false}
          slideDuration={0.1}
        />
      </div>
    )
  );
};

export default ImageGalleryWrapper;
