import {FunctionComponent} from 'react';
import styles from './marker-markup.module.css';

interface Props {
  title: string;
  src: string;
}
const MarkerMarkup: FunctionComponent<Props> = props => {
  const {title, src} = props;

  return (
    <div className={styles.marker}>
      <img
        src={src}
        alt={title}
        className={styles['marker-image']}
        loading="lazy"
      />
    </div>
  );
};

export default MarkerMarkup;
