import {InputType, ParamNames} from '@customTypes/app';

export const getParamsFromUrl = (): {
  type: InputType;
  value: string[];
  selectedImageId: string | null;
} => {
  const hash = window.location.hash.replace('#/', '');
  const params = new URLSearchParams(hash);

  const getTypeAndValue = (params: URLSearchParams) => {
    const tag = params.get(InputType.TAG);
    const username = params.get(InputType.USERNAME);

    if (tag) {
      return {type: InputType.TAG, value: tag.split('&')};
    }

    if (username) {
      return {type: InputType.USERNAME, value: username.split('&')};
    }

    return {type: InputType.NONE, value: []};
  };

  const {type, value} = getTypeAndValue(params);

  const selectedImageId = params.get(ParamNames.ID) || null;

  // Manually parse the 'flickr' parameter from the URL
  // This is needed because the URLSearchParams API doesn't support and we want to extract the flickr parameter from older URLs
  const flickrParam = hash
    .split('&')
    .find(param => param.startsWith(ParamNames.FLICKR));
  const flickrValue = flickrParam ? flickrParam.split(':')[1] : null;

  // Replace the 'flickr' parameter from the URL
  if (flickrParam && flickrValue) {
    const updatedHash = hash.replace(flickrParam, `id=${flickrValue}`);
    window.location.hash = `#/${updatedHash}`;
  }

  return {
    type,
    value,
    selectedImageId: selectedImageId || flickrValue || null
  };
};
